import User from '@/classes/User'

const UserRoleTypes = {
  MEMBER: '',
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPERADMIN',
}

UserRoleTypes.label = {}
UserRoleTypes.label[UserRoleTypes.MEMBER] = 'Member'
UserRoleTypes.label[UserRoleTypes.ADMIN] = 'Admin'
UserRoleTypes.label[UserRoleTypes.SUPERADMIN] = 'Super Admin'

UserRoleTypes.getOptions = () => {
  const options = []
  Object.keys(UserRoleTypes.label).forEach(value => {
    if (value === UserRoleTypes.SUPERADMIN && !User.isSuperAdmin) {
      return
    }
    options.push({ value, text: UserRoleTypes.label[value] })
  })

  return options
}

UserRoleTypes.getLabel = roleRaw => {
  const role = roleRaw || UserRoleTypes.MEMBER

  if (UserRoleTypes.label?.[role]) {
    return UserRoleTypes.label[role]
  }

  return '???'
}

export default UserRoleTypes
