<template>
  <v-card class="mb-5">
    <v-dialog v-model="addNewModal" width="80%" max-width="850">
      <AdminCreateUser :organisation-uuid="organisationUuid" />
    </v-dialog>
    <v-dialog v-model="editModal" width="80%" max-width="850" @close="editItem = null">
      <AdminEditUser :entity="editItem" />
    </v-dialog>

    <ResultMsg ref="resultMsg"></ResultMsg>

    <ConfirmationDialog
      ref="confirmationDialog"
      :message="deleteUserConfirmText"
      title="Delete User"
      confirm-text="DELETE"
      @confirmed="onDeleteConfirmed"
    ></ConfirmationDialog>

    <v-sheet class="d-flex mb-0">
      <v-sheet class="mr-auto">
        <v-card-title> Users List </v-card-title>
        <v-card-text>
          Here is the list of
          <span v-if="groupUuid"> all users that you manage for organisation "{{ groupName }}". </span>
          <span v-else-if="organisationUuid"> users for workspace "{{ organisationName }}". </span>
          <span v-else>all users that you manage.</span>
        </v-card-text>
      </v-sheet>

      <v-sheet class="pa-4">
        <v-btn color="primary" @click="addNewModal = true">
          <v-icon>{{ icons.mdiPlusCircle }}</v-icon>
          &nbsp; NEW USER
        </v-btn>
      </v-sheet>
    </v-sheet>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="userList"
      item-key="sub"
      class="table-rounded"
      show-select
      :items-per-page="30"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 30, 50, 100, -1],
      }"
    >
      <template #[`item.email`]="{ item }"
        >{{ item.email.split('@')[0] }}<wbr />@{{ item.email.split('@')[1] }}</template
      >
      <template #[`item.organisationName`]="{ item }">
        <router-link :to="{ name: 'admin-workspace', params: { organisationUuid: item.organisationUuid } }">
          {{ Organisations.getNameById(item.organisationUuid) }}
        </router-link>
      </template>
      <template #[`item.groupName`]="{ item }">
        <router-link :to="{ name: 'admin-group', params: { groupUuid: item.groupUuid } }">
          {{ Groups.getNameById(item.groupUuid) }}
        </router-link>
      </template>
      <template #[`item.role`]="{ item }">
        {{ UserRoleTypes.getLabel(item.role) }}
      </template>
      <template #[`item.UserStatus`]="{ item }">
        {{ item.UserStatus.replaceAll('_', ' ') }}
      </template>
      <template #[`item.UserCreateDate`]="{ item }"
        >{{ item.UserCreateDate.slice(0, 10) }} {{ item.UserCreateDate.slice(11, 19) }}</template
      >
      <template #[`item.UserLastModifiedDate`]="{ item }"
        >{{ item.UserLastModifiedDate.slice(0, 10) }} {{ item.UserLastModifiedDate.slice(11, 19) }}</template
      >
      <template #[`item.actions`]="{ item }">
        <div class="mt-1 row-actions">
          <v-btn
            v-if="User.isSuperAdmin || item.role !== UserRoleTypes.SUPERADMIN"
            color="primary"
            class="mb-1 mr-1 pa-0"
            title="Edit User"
            @click="clickEditUser(item)"
          >
            <v-icon>{{ icons.mdiAccountEdit }}</v-icon>
          </v-btn>
          <v-btn
            v-if="User.isSuperAdmin && item.role === UserRoleTypes.ADMIN"
            color="primary"
            class="mb-1 mr-1 pa-0"
            title="Edit User Permissions"
            :to="{ name: 'admin-list-user-permissions', params: { email: item.email } }"
          >
            <v-icon>{{ icons.mdiKeyVariant }}</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            class="mb-1 mr-1 pa-0"
            :loading="item.isResetting"
            title="Reset User"
            @click="clickResetUser(item)"
          >
            <v-icon>{{ icons.mdiLockReset }}</v-icon>
          </v-btn>
          <v-btn
            v-if="User.isSuperAdmin || item.role !== UserRoleTypes.SUPERADMIN"
            color="error"
            class="mb-1 mr-1 pa-0"
            :loading="item.isDeleting"
            title="Delete User"
            @click="clickDeleteUser(item)"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import icons from '@/utils/icons'
import escapeRegExp from '@/utils/escapeRegExp'
import EventBus from '@/classes/EventBus'
import UploadsApi from '@/classes/UploadsApi'
import User from '@/classes/User'
import UserRoleTypes from '@/classes/UserRoleTypes'
import Groups from '@/classes/Groups'
import Organisations from '@/classes/Organisations'
import Users from '@/classes/Users'
import ConfirmationDialog from '@/views/utils/ConfirmationDialog.vue'
import ResultMsg from '@/views/utils/ResultMsg.vue'
import AdminCreateUser from '@/views/admin/AdminCreateUser.vue'
import AdminEditUser from '@/views/admin/AdminEditUser.vue'

export default {
  components: {
    ConfirmationDialog,
    ResultMsg,
    AdminCreateUser,
    AdminEditUser,
  },
  props: {
    organisationUuid: {
      type: String,
      default: null,
    },
    groupUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      Groups,
      Organisations,
      User,
      UserRoleTypes,
      groupName: '???',
      organisationName: '???',
      userList: [],
      selected: [],
      loading: true,
      headers: [
        { text: 'EMAIL', value: 'email' },
        { text: 'WORKSPACE', value: 'organisationName' },
        { text: 'ORG', value: 'groupName' },
        { text: 'ROLE', value: 'role' },
        { text: 'STATUS', value: 'UserStatus' },
        { text: 'CREATED', value: 'UserCreateDate' },
        { text: 'MODIFIED', value: 'UserLastModifiedDate' },
        { text: 'ID', value: 'sub' },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ],
      icons,
      addNewModal: false,
      editModal: false,
      editItem: null,
      deleteUserConfirmText: null,
    }
  },
  mounted() {
    Users.onLoaded(() => {
      this.applyFilters()
      this.loading = false
    })
    EventBus.$on('userCreated', () => {
      this.loading = true
      this.addNewModal = false
    })
    EventBus.$on('userEdited', () => {
      this.loading = true
      this.editModal = false
      this.editItem = null
    })
    Organisations.onLoaded(() => {
      this.$forceUpdate()
      if (this.organisationUuid) {
        this.organisationName = Organisations.getNameById(this.organisationUuid)
      }
    })
    Groups.onLoaded(() => {
      this.$forceUpdate()
      if (this.groupUuid) {
        this.groupName = Groups.getNameById(this.groupUuid)
      }
    })
    EventBus.$on('adminUserListFilter', filterEvent => {
      this.filterEvent = filterEvent
      this.applyFilters()
    })
  },
  methods: {
    applyFilters() {
      this.userList = Users.getAll().filter(user => {
        if (this.organisationUuid) {
          if (this.organisationUuid !== user?.organisationUuid) {
            return false
          }
        }

        if (this.groupUuid) {
          if (this.groupUuid !== user?.groupUuid) {
            return false
          }
        }

        if (this.filterEvent?.userSearch) {
          const searchRegex = new RegExp(escapeRegExp(this.filterEvent.userSearch), 'i')
          if (!(user?.email.match(searchRegex) || user?.sub.match(searchRegex))) {
            return false
          }
        }

        if (this.filterEvent?.userOrganisation?.length) {
          if (!this.filterEvent.userOrganisation.includes(user?.organisationUuid)) {
            return false
          }
        }

        if (this.filterEvent?.userStatus?.length) {
          if (!this.filterEvent.userStatus.includes(user?.UserStatus)) {
            return false
          }
        }

        return true
      })
    },
    refreshList() {
      this.loading = true
      Users.refresh()
    },
    clickEditUser(user) {
      this.editItem = user
      this.editModal = true
    },
    async clickResetUser(userRaw) {
      const user = userRaw
      const { email } = user
      user.isResetting = true
      this.$forceUpdate()
      const response = await UploadsApi.adminResetUser({ email })
      user.isResetting = false
      this.refreshList()
      this.$forceUpdate()
      this.$refs.resultMsg.responseMsg(response)
    },
    async clickDeleteUser(user) {
      this.editItem = user
      this.deleteUserConfirmText = `Are you sure you want to delete user ${user.email}? This action cannot be undone.`
      this.$refs.confirmationDialog.openDialog()
    },
    async onDeleteConfirmed() {
      this.loading = true
      const user = this.editItem
      const { email } = user
      user.isDeleting = true
      this.$forceUpdate()
      const response = await UploadsApi.adminDeleteUser({ email })
      user.isDeleting = false
      this.refreshList()
      this.$forceUpdate()
      this.$refs.resultMsg.responseMsg(response)
    },
  },
}
</script>

<style lang="scss" scoped>
.row-actions {
  margin-right: -4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.row-actions .v-btn {
  min-width: 38px;
}
</style>
