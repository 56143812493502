<template>
  <v-card class="mb-5">
    <v-card-title> Edit User </v-card-title>
    <v-card-text>Use this form to edit user {{ entity ? entity.email : '???' }}</v-card-text>
    <v-card-text>
      <v-alert v-if="errorMsg" type="error">{{ errorMsg }}</v-alert>
      <v-form ref="form" @submit.prevent="onSubmitForm">
        <v-text-field v-model="userEmail" label="Email" :rules="userEmailRules" readonly disabled></v-text-field>
        <v-autocomplete
          v-model="userOrganisation"
          label="Workspace"
          clearable
          :items="organisationList"
          item-value="organisationUuid"
          item-text="organisationName"
          no-data-text="No results found"
        ></v-autocomplete>
        <v-select v-model="userRole" :items="userRoleOptions" label="Role"></v-select>
        <v-btn type="submit" color="success" class="mr-2 mt-2" :loading="isSubmitting">
          <v-icon>{{ icons.mdiCheckCircle }}</v-icon>
          &nbsp; Submit
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import icons from '@/utils/icons'
import EventBus from '@/classes/EventBus'
import Organisations from '@/classes/Organisations'
import UploadsApi from '@/classes/UploadsApi'
import UserRoleTypes from '@/classes/UserRoleTypes'
import requiredRule from '@/rules/requiredRule'

export default {
  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      userEmail: this.entity?.email,
      userEmailRules: [requiredRule],
      userOrganisation: this.entity?.organisationUuid,
      userOrganisationRules: [requiredRule],

      userRole: this.entity?.role || UserRoleTypes.MEMBER,
      userRoleOptions: UserRoleTypes.getOptions(),
      icons,

      isSubmitting: false,
      Organisations,
      organisationList: [{ organisationName: 'Loading...' }],
      errorMsg: null,
    }
  },
  watch: {
    entity: function entity() {
      // update fields when user entity changes
      this.userEmail = this.entity?.email
      this.userOrganisation = this.entity?.organisationUuid
      this.userRole = this.entity?.role || UserRoleTypes.MEMBER
      this.errorMsg = null
      this.refreshOrganisationList()
    },
  },
  mounted() {
    Organisations.onLoaded(() => {
      this.refreshOrganisationList()
    })
  },
  methods: {
    refreshOrganisationList() {
      this.organisationList = Organisations.getAll()
    },
    async onSubmitForm() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }

      this.errorMsg = null
      this.isSubmitting = true
      const user = {
        email: this.entity.email,
        organisationUuid: this.userOrganisation,
        role: this.userRole,
      }

      const response = await UploadsApi.adminPersistUser(user)
      if (!response?.success) {
        this.errorMsg = response?.message || 'An unexpected error occurred'

        // todo: ui error msg
        // todo: render errors if there are any https://stackoverflow.com/questions/48613643/server-side-form-validation-with-vue-js-and-vuetify
        // if (response.errors?.userName) {
        //   console.log('submit response.errors?.userName', response.errors?.userName)
        // }
      } else {
        // success - fire event
        this.errorMsg = null
        EventBus.$emit('userEdited', user)
      }
      this.isSubmitting = false
    },
  },
}
</script>
