import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c(VDialog,{attrs:{"width":"80%","max-width":"850"},model:{value:(_vm.addNewModal),callback:function ($$v) {_vm.addNewModal=$$v},expression:"addNewModal"}},[_c('AdminCreateUser',{attrs:{"organisation-uuid":_vm.organisationUuid}})],1),_c(VDialog,{attrs:{"width":"80%","max-width":"850"},on:{"close":function($event){_vm.editItem = null}},model:{value:(_vm.editModal),callback:function ($$v) {_vm.editModal=$$v},expression:"editModal"}},[_c('AdminEditUser',{attrs:{"entity":_vm.editItem}})],1),_c('ResultMsg',{ref:"resultMsg"}),_c('ConfirmationDialog',{ref:"confirmationDialog",attrs:{"message":_vm.deleteUserConfirmText,"title":"Delete User","confirm-text":"DELETE"},on:{"confirmed":_vm.onDeleteConfirmed}}),_c(VSheet,{staticClass:"d-flex mb-0"},[_c(VSheet,{staticClass:"mr-auto"},[_c(VCardTitle,[_vm._v(" Users List ")]),_c(VCardText,[_vm._v(" Here is the list of "),(_vm.groupUuid)?_c('span',[_vm._v(" all users that you manage for organisation \""+_vm._s(_vm.groupName)+"\". ")]):(_vm.organisationUuid)?_c('span',[_vm._v(" users for workspace \""+_vm._s(_vm.organisationName)+"\". ")]):_c('span',[_vm._v("all users that you manage.")])])],1),_c(VSheet,{staticClass:"pa-4"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.addNewModal = true}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlusCircle))]),_vm._v("   NEW USER ")],1)],1)],1),_c(VDataTable,{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.userList,"item-key":"sub","show-select":"","items-per-page":30,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 30, 50, 100, -1],
    }},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(item.email.split('@')[0])),_c('wbr'),_vm._v("@"+_vm._s(item.email.split('@')[1]))]}},{key:"item.organisationName",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'admin-workspace', params: { organisationUuid: item.organisationUuid } }}},[_vm._v(" "+_vm._s(_vm.Organisations.getNameById(item.organisationUuid))+" ")])]}},{key:"item.groupName",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'admin-group', params: { groupUuid: item.groupUuid } }}},[_vm._v(" "+_vm._s(_vm.Groups.getNameById(item.groupUuid))+" ")])]}},{key:"item.role",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UserRoleTypes.getLabel(item.role))+" ")]}},{key:"item.UserStatus",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.UserStatus.replaceAll('_', ' '))+" ")]}},{key:"item.UserCreateDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(item.UserCreateDate.slice(0, 10))+" "+_vm._s(item.UserCreateDate.slice(11, 19)))]}},{key:"item.UserLastModifiedDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(item.UserLastModifiedDate.slice(0, 10))+" "+_vm._s(item.UserLastModifiedDate.slice(11, 19)))]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"mt-1 row-actions"},[(_vm.User.isSuperAdmin || item.role !== _vm.UserRoleTypes.SUPERADMIN)?_c(VBtn,{staticClass:"mb-1 mr-1 pa-0",attrs:{"color":"primary","title":"Edit User"},on:{"click":function($event){return _vm.clickEditUser(item)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiAccountEdit))])],1):_vm._e(),(_vm.User.isSuperAdmin && item.role === _vm.UserRoleTypes.ADMIN)?_c(VBtn,{staticClass:"mb-1 mr-1 pa-0",attrs:{"color":"primary","title":"Edit User Permissions","to":{ name: 'admin-list-user-permissions', params: { email: item.email } }}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiKeyVariant))])],1):_vm._e(),_c(VBtn,{staticClass:"mb-1 mr-1 pa-0",attrs:{"color":"primary","loading":item.isResetting,"title":"Reset User"},on:{"click":function($event){return _vm.clickResetUser(item)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiLockReset))])],1),(_vm.User.isSuperAdmin || item.role !== _vm.UserRoleTypes.SUPERADMIN)?_c(VBtn,{staticClass:"mb-1 mr-1 pa-0",attrs:{"color":"error","loading":item.isDeleting,"title":"Delete User"},on:{"click":function($event){return _vm.clickDeleteUser(item)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiClose))])],1):_vm._e()],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }