import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c(VCardTitle,[_vm._v(" Edit User ")]),_c(VCardText,[_vm._v("Use this form to edit user "+_vm._s(_vm.entity ? _vm.entity.email : '???'))]),_c(VCardText,[(_vm.errorMsg)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.errorMsg))]):_vm._e(),_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Email","rules":_vm.userEmailRules,"readonly":"","disabled":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c(VAutocomplete,{attrs:{"label":"Workspace","clearable":"","items":_vm.organisationList,"item-value":"organisationUuid","item-text":"organisationName","no-data-text":"No results found"},model:{value:(_vm.userOrganisation),callback:function ($$v) {_vm.userOrganisation=$$v},expression:"userOrganisation"}}),_c(VSelect,{attrs:{"items":_vm.userRoleOptions,"label":"Role"},model:{value:(_vm.userRole),callback:function ($$v) {_vm.userRole=$$v},expression:"userRole"}}),_c(VBtn,{staticClass:"mr-2 mt-2",attrs:{"type":"submit","color":"success","loading":_vm.isSubmitting}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiCheckCircle))]),_vm._v("   Submit ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }