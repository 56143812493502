<template>
  <v-card class="mb-5">
    <v-card-title> Create a New User </v-card-title>
    <v-card-text>Use this form to create a new user.</v-card-text>
    <v-card-text>
      <v-alert v-if="errorMsg" type="error">{{ errorMsg }}</v-alert>
      <v-form ref="form" @submit.prevent="onSubmitForm">
        <v-text-field v-model="userEmail" label="Email" :rules="userEmailRules"></v-text-field>
        <v-autocomplete
          v-if="!organisationUuid"
          v-model="userOrganisation"
          label="Workspace"
          clearable
          :items="organisationList"
          item-value="organisationUuid"
          item-text="organisationName"
          no-data-text="No results found"
        ></v-autocomplete>
        <v-select v-model="userRole" :items="userRoleOptions" label="Role"></v-select>
        <v-btn type="submit" color="success" class="mr-2 mt-2" :loading="isSubmitting">
          <v-icon>{{ icons.mdiCheckCircle }}</v-icon>
          &nbsp; Submit
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import icons from '@/utils/icons'
import EventBus from '@/classes/EventBus'
import Organisations from '@/classes/Organisations'
import UploadsApi from '@/classes/UploadsApi'
import UserRoleTypes from '@/classes/UserRoleTypes'
import requiredRule from '@/rules/requiredRule'

export default {
  props: {
    organisationUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      userEmail: '',
      userEmailRules: [requiredRule],
      userOrganisation: '',
      userOrganisationRules: [requiredRule],

      userRole: UserRoleTypes.MEMBER,
      userRoleOptions: UserRoleTypes.getOptions(),

      icons,

      isSubmitting: false,
      Organisations,
      organisationList: [{ organisationName: 'Loading...' }],
      errorMsg: null,
    }
  },
  mounted() {
    Organisations.onLoaded(organisations => {
      this.organisationList = organisations
    })
  },
  methods: {
    async onSubmitForm() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }

      this.errorMsg = null
      this.isSubmitting = true
      const user = {
        email: this.userEmail,
        organisationUuid: this.organisationUuid || this.userOrganisation,
        role: this.userRole,
      }

      // todo try/catch
      const response = await UploadsApi.adminPersistUser(user)
      if (!response?.success) {
        this.errorMsg = response?.message || 'An unexpected error occurred'

        // todo: ui error msg
        // todo: render errors if there are any https://stackoverflow.com/questions/48613643/server-side-form-validation-with-vue-js-and-vuetify
        // if (response.errors?.userName) {
        //   console.log('submit response.errors?.userName', response.errors?.userName)
        // }
      } else {
        // no errors - reset form
        // todo: ui success msg
        this.errorMsg = null
        this.$refs.form.reset()
        this.userRole = UserRoleTypes.MEMBER
        EventBus.$emit('userCreated', user)
      }
      this.isSubmitting = false
    },

    // openErrorDialog(thisFile) {
    //   this.errorDialogFile = thisFile
    //   this.errorDialog = true
    // },
    // closeErrorDialog() {
    //   this.errorDialog = false
    // },
  },
}
</script>
